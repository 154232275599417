import React from "react";
import { AiOutlineDesktop } from "react-icons/ai";

export function NoPermission() {
  return (
    <div
      style={{
        textAlign: "center",
        paddingTop: 200
      }}
    >
      <AiOutlineDesktop style={{ fontSize: 100 }} />
      <div style={{ fontSize: 20, fontWeight: 500 }}>您无权限访问系统，请联系管理员</div>
    </div>
  );
}
