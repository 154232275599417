import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { SettingService } from "../../api/SettingService";
import { SysSetting } from "../../model/SysSetting";

const initialState: SysSetting[] = [];

const settingService = new SettingService();
export const getSysSetting = createAsyncThunk(
  "setting/getSysSetting",
  async (data, { rejectWithValue }) => {
    try {
      const settings = await settingService.getAllSysSetting();
      return settings;
    } catch (err) {
      return rejectWithValue("获取系统配置信息错误，请重试！");
    }
  }
);

export const sysSettingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(getSysSetting.fulfilled, (state: SysSetting, action: any) => action.payload);
  }
});

export const selectSysSetting = (state: RootState) => state.sysSetting;
