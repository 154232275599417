import { CommonService } from "./CommonService";

export class RoleService extends CommonService {
  request;
  constructor() {
    super();
    this.request = this.getService();
  }

  /**
   * 获取全部角色
   * @returns
   */
  getAllRoles() {
    return this.request({
      url: "/role",
      method: "get"
    });
  }

  /**
   * 获取某个角色的权限集合
   * @param roleId 角色id
   * @returns
   */
  getRolePermissions(roleId: string) {
    return this.request({
      url: `/role/menu/role/${roleId}`,
      method: "get"
    });
  }
}
