import { CommonService } from "./CommonService";

export class UserService extends CommonService {
  request;
  constructor() {
    super();
    this.request = this.getService();
  }

  // 根据用户名密码登录
  loginByUsername(username: string, password: string) {
    return this.request({
      url: "/login/backend/login",
      method: "post",
      // eslint-disable-next-line @typescript-eslint/camelcase
      data: { userName: username, password }
    });
  }

  // 根据手机号登录
  loginByPhone(phone: string, code: string) {
    return this.request({
      url: "/login/sms/login",
      method: "post",
      // eslint-disable-next-line @typescript-eslint/camelcase
      data: { phone: phone, smsCode: code }
    });
  }

  // 发送短信验证码
  sendSmsCode(phone: number) {
    return this.request({
      url: `/login/sms/${phone}/send`,
      method: "get"
    });
  }

  // 获取当前用户信息
  getUserInfo() {
    return this.request({
      url: `/user`,
      method: "get"
    });
  }

  /**
   * 获取某个用户信息
   * @param id 用户id
   * @returns
   */
  getUserInfoById(id: string) {
    return this.request({
      url: `/users/${id}`,
      method: "get"
    });
  }

  getAllUser(query: any) {
    return this.request({
      url: "/users",
      method: "get",
      params: query
    });
  }

  /**
   * 新增用户
   * @param user 用户类
   * @returns
   */
  addUser(user: any) {
    return this.request({
      url: `/users`,
      method: "post",
      data: user
    });
  }

  /**
   * 修改用户
   * @param user 用户类
   * @returns
   */
  updateUser(user: any) {
    return this.request({
      url: `/users`,
      method: "put",
      data: user
    });
  }
}
