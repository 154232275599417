import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { clearStore } from "../common/Store";
import { sysSettingSlice } from "./setting/slice";
import { userSlice } from "./user/slice";

// 合并多个reducer
const appReducer = combineReducers({
  userInfo: userSlice.reducer,
  sysSetting: sysSettingSlice.reducer
});

// 处理登出时，清理工作
const rootReducer = (state: any, action: any) => {
  if (action.type === "LOGOUT") {
    state = undefined;
    clearStore();
  }
  return appReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  devTools: true
});

export type AppDispatch = typeof store.dispatch;
export function useAppDispatch() {
  return useDispatch<AppDispatch>();
}
// 获取全部store数据类型
export type RootState = ReturnType<typeof store.getState>;

export default store;
