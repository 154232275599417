import React, { lazy, Suspense, useEffect } from "react";
import { useHistory } from "react-router";
import { Route, Switch } from "react-router-dom";
import "./App.css";
import { getStore } from "./common/Store";
import { NotFound } from "./components/error-page/404";
import { NoPermission } from "./components/error-page/NoPermission";

const Home = lazy(() => import("./view/home/Home"));
const Login = lazy(() => import("./view/login/Login"));

function App() {
  const history = useHistory();

  useEffect(() => {
    const noPermissionPath = ["/404", "/no-permission"];
    const path = history.location.pathname;
    if (noPermissionPath.indexOf(path) === -1) {
      const token = getStore("token");
      if (!token) {
        history.push("/login");
      }
    }
  }, [history]);

  return (
    <Suspense
      fallback={
        <div className="layout-content-panel">
          <div className="layout-loading">加载中...</div>
        </div>
      }
    >
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/404" component={NotFound} />
        <Route exact path="/no-permission" component={NoPermission} />
        <Route path="/" component={Home} />
      </Switch>
    </Suspense>
  );
}

export default App;
