import React from "react";

export function NotFound() {
  return (
    <div
      style={{
        width: 200,
        margin: "0 auto",
        textAlign: "center",
        position: "relative",
        top: "50%",
        transform: "translateY(-70%)"
      }}
    >
      <div style={{ fontSize: 100, fontWeight: "bold" }}>404</div>
      <div style={{ fontSize: 24, fontWeight: 500 }}>页面未找到</div>
    </div>
  );
}
