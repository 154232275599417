import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { RoleService } from "../../api/RoleService";
import { UserService } from "../../api/UserService";
import { Menu } from "../../model/Menu";
import { User } from "../../model/User";
export const mockMenus = [
  {
    id: "4",
    component: "view/goods/Goods",
    menuCode: "goods",
    menuIcon: "icon-pro-goods",
    menuName: "商品管理",
    menuPath: "/",
    menuStatus: true
  },
  {
    id: "1",
    component: "view/role/Role",
    menuCode: "roles",
    menuIcon: "icon-roles",
    menuName: "角色管理",
    menuPath: "/roles",
    menuStatus: true
  },
  {
    id: "3",
    component: "view/commission/WithdrawList",
    menuCode: "WithdrawList",
    menuIcon: "icon-dashboard",
    menuName: "提现记录",
    menuPath: "/withdrawlist",
    menuStatus: true
  },
  {
    id: "6",
    component: "view/order/Order",
    menuCode: "orderList",
    menuIcon: "icon-order",
    menuName: "订单管理",
    menuPath: "/orders",
    menuStatus: true
  },
  {
    id: "8",
    component: "view/staff/NurseList",
    menuCode: "nurseList",
    menuIcon: "icon-user",
    menuName: "护工管理",
    menuPath: "/staff/NurseList",
    menuStatus: true
  },
  {
    id: "9",
    component: "view/staff/StaffList",
    menuCode: "StaffList",
    menuIcon: "icon-user",
    menuName: "员工管理",
    menuPath: "/staff/StaffList",
    menuStatus: true
  },
  {
    id: "10",
    component: "view/staff/UserList",
    menuCode: "UserList",
    menuIcon: "icon-user",
    menuName: "用户管理",
    menuPath: "/userList",
    menuStatus: true
  },
  {
    id: "5",
    component: "view/nursing/NursingInstitution",
    menuCode: "NursingInstitution",
    menuIcon: "icon-institutions",
    menuName: "机构管理",
    menuPath: "/orgs",
    menuStatus: true
  },
  {
    id: "11",
    component: "view/supplier/Supplier",
    menuCode: "Supplier",
    menuIcon: "icon-supplier",
    menuName: "供应商管理",
    menuPath: "/supplier",
    menuStatus: true
  },
  {
    id: "2",
    component: "view/commission/Schedule",
    menuCode: "commissions",
    menuIcon: "icon-dashboard",
    menuName: "结算设置",
    menuPath: "/commissions",
    menuStatus: true
  },
  {
    id: "12",
    component: "view/setting/SysSetting",
    menuCode: "setting",
    menuIcon: "icon-setting",
    menuName: "系统设置",
    menuPath: "/setting",
    menuStatus: true
  }
] as Menu[];
export interface UserInfoState {
  menus: Menu[];
  user: User;
}

const initialState: UserInfoState = {
  menus: [],
  user: {} as User
};

const userService = new UserService();
const roleService = new RoleService();
export const getUserInfo = createAsyncThunk(
  "user/getUserInfo",
  async (data, { rejectWithValue }) => {
    try {
      // const user: User = {} as User;
      const user: User = await userService.getUserInfo();
      if (!user.roleId) {
        return rejectWithValue("该用户不存在！");
      }
      // 用户菜单
      const roleId = user.roleId;
      const menus: Menu[] = await roleService.getRolePermissions(roleId);
      // const menus: Menu[] = mockMenus;

      // 所有父菜单
      const parentMenus = menus.filter(item => !item.parent);
      // 所有子菜单
      const childMenus = menus.filter(item => item.parent);
      const allMenus = parentMenus.map((item: Menu) => {
        const key = item.id;
        const children = childMenus.filter(c => c.parent?.id === key);
        item.children = children;
        return item;
      });
      return {
        menus: allMenus,
        user: user
      };
    } catch (err) {
      return rejectWithValue("获取用户信息错误，请重试！");
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(getUserInfo.fulfilled, (state: UserInfoState, action: any) => action.payload);
  }
});

export const selectUser = (state: RootState) => state.userInfo;
