import { NetService } from "@quege/utils";
import { message } from "antd";
import { getStore } from "../common/Store";
import { ResponseError } from "../model/ResponseError";
import store from "../store";

export class CommonService {
  netService;
  constructor() {
    const service = new NetService("/api/v1");
    // 添加header
    service.interceptRequest((config: any) => {
      const token = getStore("token");
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }
      return config;
    });

    service.interceptResponse(undefined, (res: any) => {
      const msg: ResponseError = {
        code: 0,
        message: "",
        type: ""
      };
      if (res.response) {
        const errorMsg = res.response.data;
        if (res.response.status === 400) {
          msg.code = errorMsg.status;
          msg.message = errorMsg.detail;
          msg.type = errorMsg.type;
          return Promise.reject(msg);
        } else if (res.response.status === 401) {
          message.error("登录过期，请重新登录");
          store.dispatch({ type: "LOGOUT" });
          window.location.href = `${window.location.origin}/login`;
          return;
        } else if (res.response.status === 404) {
          msg.code = 404;
          msg.message = `${res.config.url} 报错404`;
        } else if (errorMsg && errorMsg.detail) {
          msg.code = Number(res.response.status);
          msg.message = errorMsg.detail;
          msg.type = errorMsg.code;
        } else {
          msg.message = "内部错误";
        }
      } else {
        msg.message = `请求错误` + res;
      }
      message.error(msg.message);
      return Promise.reject(msg);
    });
    this.netService = service.createService();
  }
  getService() {
    return this.netService;
  }
}
