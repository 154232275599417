import { CommonService } from "./CommonService";

export class SettingService extends CommonService {
  request;
  constructor() {
    super();
    this.request = this.getService();
  }

  /**
   * 获取所有系统配置
   * @param param 参数
   * @returns
   */
  getAllSysSetting(params?: any) {
    return this.request({
      url: "/globalSetting",
      method: "get",
      params
    });
  }

  /**
   * 新增系统配置
   * @param setting 配置类
   * @returns
   */
  addSysSetting(setting: any) {
    return this.request({
      url: `/globalSetting`,
      method: "post",
      data: setting
    });
  }

  /**
   * 修改系统配置
   * @param setting 配置类
   * @returns
   */
  updateSysSetting(setting: any) {
    return this.request({
      url: `/globalSetting`,
      method: "put",
      data: setting
    });
  }

  /**
   * 删除某条配置
   * @param id id
   * @returns
   */
  deleteSysSetting(id: string) {
    return this.request({
      url: `/globalSetting/${id}`,
      method: "delete"
    });
  }

  /**
   * 获取护理机构提成的下次结算时间
   * @returns
   */
  getNextOrgBonusPlanDate() {
    return this.request({
      url: `bouns/orgPlan/next`,
      method: "get"
    });
  }

  /**
   * 获取护工提成的下次结算时间
   * @returns
   */
  getNextNurseBonusPlanDate() {
    return this.request({
      url: `bouns/workerPlan/next`,
      method: "get"
    });
  }
}
