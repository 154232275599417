const keyName = "huli-";
/**
 * 存储Storage
 */
export const setStore = (params: any, isSession = false) => {
  let { name, content } = params;
  name = keyName + name;
  const obj = {
    dataType: typeof content,
    content: content,
    dateTime: new Date().getTime()
  };
  if (isSession) window.sessionStorage.setItem(name, JSON.stringify(obj));
  else window.localStorage.setItem(name, JSON.stringify(obj));
};
/**
 * 获取Storage
 */

export const getStore = (key: string) => {
  const name = keyName + key;
  let obj: any;
  let content;
  obj = window.sessionStorage.getItem(name);
  if (!obj) obj = window.localStorage.getItem(name);
  if (!obj) return;
  try {
    obj = JSON.parse(obj);
  } catch {
    return obj;
  }

  if (obj.dataType === "string") {
    content = obj.content;
  } else if (obj.dataType === "number") {
    content = Number(obj.content);
  } else if (obj.dataType === "boolean") {
    content = Boolean(obj.content);
  } else if (obj.dataType === "object") {
    content = obj.content;
  }
  return content;
};
/**
 * 删除Storage
 */
export const removeStore = (key: string) => {
  const name = keyName + key;
  window.sessionStorage.removeItem(name);
  window.localStorage.removeItem(name);
};

/**
 * 获取全部localStorage
 */
export const getAllStore = (isSession = false) => {
  const list = [];
  if (isSession) {
    for (let i = 0; i <= window.sessionStorage.length; i++) {
      list.push({
        name: window.sessionStorage.key(i),
        content: getStore(window.sessionStorage.key(i) ?? "")
      });
    }
  } else {
    for (let i = 0; i <= window.localStorage.length; i++) {
      list.push({
        name: window.localStorage.key(i),
        content: getStore(window.localStorage.key(i) ?? "")
      });
    }
  }
  return list;
};

/**
 * 清空全部Storage
 */
export const clearStore = () => {
  window.sessionStorage.clear();
  window.localStorage.clear();
};
